.export_btn {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  outline: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 2rem;
  width: 3.3rem;
  position: relative;
  transition: all 1s ease;
}
.export_btn p {
  display: flex;
  align-items: center;
}
.export_btn:hover {
  width: 8rem;
  transition: all 0.4s cubic-bezier(0.62, 0.1, 0.5, 1);
  border-radius: 1rem;
}
.export_btn:hover .export_btn_text {
  font-size: 1.2rem;
  opacity: 1;
  width: auto;
  transition: opacity 0.7s, width 1ms linear 0.27s, font-size 1ms linear 0.27s;
}
.export_btn .export_btn_text {
  font-weight: 600;
  color: var(--white);
  position: absolute;
  top: 0.9rem;
  left: 2.8rem;
  transition: opacity 1s;
  opacity: 0;
  white-space: nowrap;
  font-size: 0;
  width: 0;
  margin: 0;
}
.export_btn_icon {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
}
