.noContent {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	justify-content: center;
	height: 60.5vh;
}
.noContent_icon {
	height: 10rem;
	width: 10rem;
	fill: var(--light-grey);
}
.noContent_text {
	font-size: 1.7rem;
	font-weight: 600;
	color: var(--grey);
	text-align: center;
}
.noContent_text span {
	display: block;
	font-size: 1.2rem;
	font-weight: 800;
	letter-spacing: 1.5px;
	text-transform: uppercase;
}
