.noData {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.noData img {
	height: 13rem;
}
.noData p {
	font-size: 1.3rem;
	font-weight: 600;
	color: var(--grey);
}
