.container {
  grid-column: 1 / -1;
  grid-row: 1 / -1;

  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 50%;

  display: grid;
  gap: 2rem;
  grid-template-columns: 5rem repeat(4, 1fr);
  grid-template-rows: 15rem repeat(4, 1fr) 15rem;
  height: 100vh;
  width: 100%;

  position: relative;
}
@media only screen and (max-width: 1440px) {
  .container {
    grid-template-rows: 13rem repeat(4, 1fr) 13rem;
  }
}
.loginContainer {
  grid-column: 2/3;
  grid-row: 2/6;

  background-color: var(--primary-color);
  background: linear-gradient(
    180deg,
    rgba(250, 99, 119, 0.43) 0%,
    rgba(14, 22, 60, 0.42) 100%
  );
  box-shadow: 0px 5px 15px 2px rgba(255, 255, 255, 0.25);
  border-radius: 3rem;
  padding: 0 3rem;
  padding-top: 5rem;
  align-self: center;
  padding-bottom: 15rem;

  display: flex;
  gap: 1rem;
  flex-direction: column;
  /* justify-content: center; */

  position: relative;
}
.loginContainer_heading {
  font-size: 2rem;
  font-weight: 700;
  color: var(--white);
  margin-bottom: 1rem;
}
.button_container {
  margin-top: 2rem;
  display: flex;
  /* flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; */
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
.login__forgot_btn {
  color: var(--white);
  font-size: 1.3rem;
  font-weight: 600;
  transition: all 0.4s;
}
.login__forgot_btn:hover {
  transform: translateX(-0.2rem);
}
.companyInfo {
  align-self: center;
  color: var(--light-grey);
  text-align: center;
  font-weight: 600;

  position: absolute;
  bottom: 5rem;
}

.loginCards {
  grid-column: 3/4;
  grid-row: 2/6;
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 2rem;
}

.loginCard {
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    rgba(250, 99, 119, 0.43) 0%,
    rgba(14, 22, 60, 0.42) 100%
  );
  box-shadow: 0px 5px 15px 2px rgba(255, 255, 255, 0.25);
  color: var(--white);
  border-radius: 2rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  position: relative;
}
.loginCard_coming_soon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background-color: var(--white);
  color: var(--primary-color);
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 1rem;
}

/* .loginCard:hover {
	transform: scale(1.01);
} */

.loginCard__icon {
  height: 6rem;
  width: 6rem;
  fill: var(--white);
  /* stroke: var(--white); */
  transition: all 0.4s;
}
.loginCard__title {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
}
.loginCard__sub_title {
  font-size: 1.2rem;
  text-align: center;
  font-style: italic;
}

.bottom_logo {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom_logo_copyright {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
}
.bottom_logo_image {
  height: 5rem;
}
.loginCard__register_btn {
  text-decoration: underline;
  color: var(--light-grey);
  font-size: 1.2rem;
}

.loginContainer_textBoxes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 48%;
}

.loginContainer_pricing {
  grid-column: 2/4;
  grid-row: 2/4;

  display: flex;
  flex-direction: column;
}
.pricing_cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 2rem 0;
}
.pricing_card,
.pricing_card_selected {
  background: linear-gradient(
    180deg,
    rgba(250, 99, 119, 0.43) 0%,
    rgba(14, 22, 60, 0.42) 100%
  );
  box-shadow: 0px 5px 15px 2px rgba(255, 255, 255, 0.25);
  border: 1px solid transparent;
  padding: 3rem 4rem;
  border-radius: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pricing_card_selected {
  border: 1px solid var(--white);
}
.pricing_header {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.pricing_card_icon_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffa8c7;
  border-radius: 1rem;
  padding: 0.8rem;
}
.pricing_card_icon {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
}
.pricing_card_heading {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--white);
}
.pricing_card_price {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: var(--light-grey);
  font-size: 1.4rem;
  font-weight: 600;
  margin: 1rem 0;
}
.pricing_card_price_amount {
  font-size: 2.5rem;
  font-family: Poppins;
}
.pricing_card_features {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.pricing_card_feature {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.pricing_card_feature_icon_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 0.7rem;
  padding: 0.5rem;
}
.pricing_card_feature_icon {
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--white);
}
.pricing_card_feature_text {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--white);
}
.loginContainer_heading_top {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}

.loginContainer_heading_top h1 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--white);
}
.loginContainer_heading_top p {
  color: var(--light-grey);
  font-size: 1.4rem;
  font-weight: 600;
}

.congrat_icon_box {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--primary-color);
  border-radius: 50%;
  padding: 1rem;
}
.congrat_icon {
  height: 5rem;
  width: 5rem;
  fill: var(--white);
}
.congrat_text {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
  color: var(--white);
}
.congrat_sub_text {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--white);
}
.congrat_login_btn {
  text-decoration: underline;
  font-size: 1.4rem;
  color: var(--white);
  font-weight: 600;
}

.steps {
  grid-column: 5/-1;
  grid-row: 1/-1;
  align-self: center;
  justify-self: end;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.step {
  margin-right: 1rem;
  display: flex;
  gap: 2rem;
}
.step_circle,
.step_circle_done {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.step_circle::after,
.step_circle_done::after {
  content: '';
  height: 10rem;
  width: 0.2rem;
  transition: all 1s;
}
.step_circle::after {
  background-color: var(--white);
}
.step_circle_done::after {
  background-color: var(--green);
}
.step_circle div,
.step_circle_done div,
.step_circle_last div {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  transition: all 1s;
}
.step_circle div,
.step_circle_last div {
  background-color: var(--white);
}
.step_circle_done div {
  background-color: var(--green);
}
.step_name {
  color: var(--white);
  font-weight: 600;
  font-size: 1.3rem;
}

.review_company {
}
.review_company_title {
  font-size: 1.8rem;
  font-weight: 700;
}
.review_company_name {
  font-size: 1.5rem;
  font-weight: 600;
}
.review_company_name span {
  font-size: 1.3rem;
  color: var(--grey);
}
.review_company_contact,
.review_company_address {
  font-size: 1.2rem;
  font-weight: 600;
}
