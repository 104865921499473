.closeIconContainer {
  width: 100%;
  display: flex;
  margin-top: 6px;
  justify-content: flex-end;
}

.group_chat_close {
  cursor: pointer;
  height: 3rem !important;
  width: 3rem !important;
}

.groupNameInput {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: center;
}

.iconAvatarContainer {
  padding: 1.4rem;
  background: var(--primary-color);
  display: inline-block;
  border-radius: 50%;
}

.iconAvatarIcon {
  width: 6rem;
  height: 4.5rem;
}

.iconContainer {
  display: flex;
  margin-top: -16px;
  justify-content: center;
}

.groupName {
  display: block;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.modal {
  background-color: white;
  padding: 4px 8px;
  border-radius: 2.4rem;
  width: 420px;
  height: 560px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.input {
  width: 90%;
  align-self: center;
}

.inputContainer {
  justify-content: center;
  margin-top: 12px;
  display: flex;
}

.circularLoader{
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 1rem;
}

.cardList {
  overflow-y: auto;
  height: 316px;
}

.buttonContainer {
  justify-content: flex-end;
  display: flex;
  padding-right: 0.4rem;
  margin-top: 2px;
}
