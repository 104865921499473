.textBox {
	background-color: var(--white);
	border-radius: 1rem;
	border: 1px solid var(--light-grey);
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 1rem 0rem;
	/* max-height: 3rem; */
}
.textInput {
	width: 100%;
	font-family: inherit;
	font-size: 1.3rem;
	font-weight: 500;
	padding: 2rem;
	background-color: transparent;
	border: none;
	outline: none;
	height: 3rem;
	gap: 1rem;
}
.textInput::-webkit-input-placeholder {
	color: var(--light-grey);
}

.Text{
	padding-left: 2rem ;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.textArea{
	width: 100%;
	font-family: inherit;
	font-size: 1.3rem;
	font-weight: 500;
	padding: 2rem;
	border-style: none;
	border-radius: 0.5rem;
	outline: none;
	min-height: 30rem ;
}