.toast {
  position: fixed;
  top: 93%;
  text-align: left;
  z-index: 999;
  right: -100%;
  font-size: 1.4rem;
  width: 27rem;
  padding: 14px 12px;
  background-color: #ffffff;
  color: grey;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 0.3s, right 0.3s;
}

.show {
  opacity: 1;
  right: 20px;
}

.closeButton {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.closeButton:hover {
  color: #ff0000;
}

@keyframes slideInRight {
  from {
    right: -100%;
  }
  to {
    right: 20px;
  }
}

@keyframes slideOutRight {
  from {
    right: 20px;
  }
  to {
    right: -100%;
  }
}

.slideInRight {
  animation: slideInRight 0.3s;
}

.slideOutRight {
  animation: slideOutRight 0.3s;
}

.preloaderContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  overflow: hidden;
  background-color: transparent;
}

.preloader {
  height: 100%;
  background-color:red;
  animation: fillWidth 3s linear;
}

@keyframes fillWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.iconContainer {
  margin-right: 8px; /* Spacing between icon and message */
  position: relative;
}

.iconMessageContainer{
  display: 'flex';
  align-items: bottom;
}

.closeButton {
  margin-left: 8px; /* Spacing between message and close button */
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.closeButton:hover {
  color: #ff0000;
}

.preloaderContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  overflow: hidden;
  background-color: transparent;
}

.preloader {
  height: 100%;
  background-color: #ff0000;
  animation: fillWidth 3s linear;
}

@keyframes fillWidth {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.iconContainer {
  margin-right: 8px;
  position: relative;
}

.iconBackground {
  background-color: #ff0000;
  color: #fff;
  font-size: 13px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}





