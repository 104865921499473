.videoScreen {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Speaker View Styling */
.speakerView {
  width: 100%;
  height: 60%;
  background-color: #000; /* Black background for inactive speaker */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
}

.speakerVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.noActiveSpeaker {
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 100%;
  width: 100%;
  display: none;
}

.mainSpeakerImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

/* Participant Grid Styling */
.videoContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
  padding: 8px;
}

.userVideoContainer,
.peerVideoContainer {
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  width: 152rem;
  margin: 1rem;
  height: 72rem;
}

.userVideo,
.peerVideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.paticipantsScreen{
  position: absolute;
  background-color: #4d4c4c;
  top: 12rem;
  width: 52rem;
  height: 50rem;
  border-radius: 2.5rem;
  grid-template-columns: repeat(2 , 1fr);
  display: grid;
  right: 99rem;
}

.participantsGrid1{
  grid-template-rows: repeat(2 , 1fr);
  display: grid;
}

.participantsGrid2{
  grid-template-rows: repeat(2 , 1fr);
  display: grid;
}

.participant1{
  margin: 1rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.participant2{
  margin: 1rem;
}
.participant3{
  margin-top: 2rem; 
  margin-left: 1rem;
}
.participant4{
  margin: 1rem;
}

.participantsImage{
  width: 24rem;
  border-radius: 1.5rem;
  height: 22rem;
  position: absolute;
}

.participant1Mike {
  background-color: #00000040;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 2rem;
  border: none;
  position: absolute;
  bottom: 26.3rem;
  left: 11rem;
}
.participant2Mike {
  background-color: #00000040;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 2rem;
  border: none;
  position: absolute;
  bottom: 2.3rem;
  left: 11rem;
}
.participant3Mike {
  background-color: #00000040;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 2rem;
  border: none;
  position: absolute;
  top: 20.3rem;
  right: 11rem;
}
.participant4Mike {
  background-color: #00000040;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 2rem;
  border: none;
  position: absolute;
  bottom: 2.3rem;
  right: 11.5rem;
}

.muteButtonIcon  {
  width: 2rem;
  height: 1.6rem;
  padding-top: 2px;
}

.participant1Name{
  position: relative;
  top: 19rem;
  left: 1rem;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
}

.assistantmodalButton{

}

.video{
  width: 152rem;
  height: 102rem;
}


.userVideo{
  width: 74rem;
  height: 76rem;
  display: flex;
  object-fit: contain;
  margin-left: 40rem;
}