.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_content {
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  width: 445px;
  height: 600px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close_button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
