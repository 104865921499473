.chatLists_user_data {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.chatLists_user_imageBox {
  background-color: var(--white);
  border-radius: 50%;
  position: relative;
  padding: 0.2rem;
}

.chatLists_user_container {
  display: flex;
  align-items: center;
  width: 10%;
}

.chatLists_user_image {
  border-radius: 50%;
  height: 4.4rem;
  border: 2px solid white;
  box-shadow: 0px 0px 1rem 1px rgba(0, 0, 0, 0.5);
}

.card {
  padding: 14px 18px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  transition: transform 0.3s ease-out;
}

.userName {
  display: block;
  margin-left: 0.4rem;
  font-size: 1.4rem;
}

.emailName {
  display: block;
  margin-left: 0.4rem;
  font-size: 1.2rem;
  color: #979797;
}

.nameContainer {
  display: block;
}

.radioContainer {
  justify-content: space-between;
  display: flex;
  width: 82%;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 6px;
}

.checkbox {
  background: #02d302;
  border-radius: 12px;
  user-select: none;
  font-weight: 600;
}

.circular_checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.checkmark {
  color: #fff;
  font-size: 12px;
}

.loading {
  position: relative;
  background: #cccccc61;
}
.loading:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100px);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.921),
    transparent
  );
  animation: loading 0.8s infinite;
}
.cards {
  background: #fff;
  height: auto;
  margin-top: 4px 0 0px;
  width: 100%;
  overflow: hidden;
}
.card_title {
  padding: 8px;
  font-size: 22px;
  font-weight: 700;
}
.card_title.loading {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  position: relative;
}

.adminText{
  color: grey;
  font-size: 1.2rem;
  display: block;
  user-select: none;
  margin-right: 2rem;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}






/* SwipeOutDiv.css */
.swipe-out-container {
  position: relative;
  background-color: #f7f7f7;
  height: 60px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0 10px;
  overflow: hidden;
  transition: transform 0.4s ease-out;
}

.content {
  flex: 1;
}

.swipe-action {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff3b30;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.swipedOut {
  transform: translateX(-100%);
}
