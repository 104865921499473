.mainContent,
.mainContentFull {
  overflow: hidden;
  transition: all 0.5s ease;
  margin: 1.5rem 0;
  margin-right: 1.5rem;
  background-color: #f1f1f1;
  /* box-shadow: 0.5rem 0.5rem 2rem 2px #09baa51a; */
  border-radius: 2rem;
  /* position: absolute;
	left: 19rem;
	top: 0;
	right: 0;
	bottom: 0; */
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 50%;
}
.mainContent {
  grid-column: 2 / -1;
}
.mainContentFull {
  margin-left: 1.5rem;
  grid-column: 1 / -1;
}
.bottom_logo {
  position: absolute;
  bottom: 0.5rem;
  right: 3rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.bottom_logo_image {
  height: 3rem;
}
.bottom_logo_powered {
  font-size: 1.1rem;
  font-weight: 600;
}

.content {
  margin-top: 1.5rem;
  height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 3rem;
  padding-right: 3rem;
  /* padding-bottom: 3rem; */
  padding-top: 1rem;
}

/* Header css */
.header {
  margin-top: 3rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__left {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.headerLink {
  text-decoration: none;
  color: var(--grey);
  font-size: 1.3rem;
  font-weight: 600;
}
.headerLinkBtn,
.headerLinkBtnSpl {
  font-family: inherit;
  background-color: transparent;
  border: none;
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
}
.headerLinkBtn {
  color: var(--grey);
}
.headerLinkBtnSpl {
  color: var(--orange);
  border-bottom: 2px solid var(--orange);

  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.headerLinkBtn__icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--orange);
}
.headerLinkBtn__icons {
  height: 2.5rem;
  width: 2.5rem;
  fill: var(--orange);
}
.headeractive {
  color: var(--primary-color);
  font-weight: 800;
  border-bottom: 1px solid var(--primary-color);
  padding-bottom: 2px;
}
.header__title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
}
.headerResult {
  font-size: 1.2rem;
  line-height: 1;
  color: var(--grey);
  font-weight: 600;
}
.header__button {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  color: var(--white);
  font-family: inherit;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s;
}
.header__button_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
  transition: all 0.4s;
}
.header_group_chat_icon {
  height: 3.5rem !important;
  width: 3.5rem !important;
}
.header__button:hover {
  background-color: transparent;
  color: var(--primary-color);
}
.header__button:hover .header__button_icon {
  fill: var(--primary-color);
}
.header__right {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.header__link {
  text-decoration: none;
  color: var(--grey);
  font-size: 1.2rem;
  font-weight: 700;
  transition: all 0.4s;
}
.header__link:hover {
  /* transform: translateY(-.1rem); */
  color: var(--primary-color);
}
.header__logout {
  background-color: transparent;
  border: none;
  color: var(--grey);
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  transition: all 0.4s;
}
.header__logout_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);
  transition: all 0.4s;
}
.header__logout:hover {
  color: var(--primary-color);
}
.header__logout:hover .header__logout_icon {
  fill: var(--primary-color);
}

.headerTextInput {
  background-color: var(--white);
  padding: 0 1.5rem;
  border: 1px solid var(--light-grey);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.4s;
}
.headerTextInput_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--light-grey);
  transition: all 0.4s;
}
.headerTextBox {
  padding: 1rem;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 700;
  height: 100%;
  width: 100%;
}
.headerTextBox::-webkit-input-placeholder {
  color: var(--light-grey);
}

.dropdown {
  z-index: 1;
  display: inline-block;
  position: relative;
}
.dropdown-a {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown-a svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);
}
.dropdown-a span {
  position: absolute;
  top: -1.5rem;
  right: -0.5rem;
  font-size: 1rem;
  color: var(--white);
  background-color: rgba(255, 123, 1, 0.8);
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}
.dropdown input:after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  display: block;
  background: #c63d0f;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: bottom left;
  transition-duration: 0.2s;
  -webkit-transform: scaleX(0);
  -webkit-transform-origin: bottom left;
  -webkit-transition-duration: 0.2s;
}
.dropdown input {
  top: -1.5rem;
  opacity: 0;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  height: 3rem;
  width: 2.3rem;
}
.dropdown input:hover {
  cursor: pointer;
}
.dropdown input:checked:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
}
.dropdown input:checked ~ .dropdown-c {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}
.dropdown-c {
  display: block;
  position: absolute;
  height: auto;
  transform: scaleY(0);
  transform-origin: top left;
  transition-duration: 0.2s;
  -webkit-transform: scaleY(0);
  -webkit-transform-origin: top left;
  -webkit-transition-duration: 0.2s;
}
.dropdown-c ul {
  margin: 0;
  margin-top: 1rem;
  padding: 1.5rem 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  list-style-type: none;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 0px 1.5rem 0px rgba(0, 0, 0, 0.2);
  transform: translateX(-95%);
  width: 30rem;
}
.dropdown-c li {
  display: block;
  position: relative;
  padding: 5px;
}
.dropdown-c .downdown-c_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 5px;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--light-grey);
}
.dropdown-c .downdown-c_heading .downdown-c_head {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--grey);
}
.dropdown-c .downdown-c_heading button {
  font-family: inherit;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  font-weight: 700;
  color: var(--grey);
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: all 0.4s;
}
.dropdown-c .downdown-c_heading button:hover {
  border-bottom: 1px solid var(--primary-color);
  color: var(--primary-color);
  /* transform: scale(1.03); */
}
.dropdown_link {
  display: block;
  position: relative;
  text-decoration: none;
  color: var(--grey);
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 1px solid #f1f1f1;
  padding: 1rem;
  background-color: var(--white);

  transition: all 0.4s;
}
.dropdown_link_btn {
  border: none;
  border-bottom: 1px solid #f1f1f1;
  font-family: inherit;
  width: 100%;
  cursor: pointer;
  text-align: left;
}
.unread {
  background-color: var(--light-grey);
  border-radius: 1rem;
  color: var(--black);
}
.dropdown_link_msg {
  display: block;
  font-size: 1rem;
}
.dropdown_link_time {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  font-style: italic;
}
.dropdown_link:hover {
  background-color: #f1f1f1;
  border-radius: 1rem;
}
.dropdown_show_more {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown_show_more button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--orange);
}

@media (max-width: 1300px) {
  .content {
    height: 80%;
  }
}
@media (max-width: 1100px) {
  .content {
    height: 75%;
  }
}

.security_text {
  display: flex;
  font-size: 1.7rem;
  font-weight: 600;
  color: var(--primary-color);
  justify-content: center;
  letter-spacing: 1px;
  gap: 0.6rem;
}

.footerText {
  position: absolute;
  bottom: 2rem;
  left: 30rem;
}
