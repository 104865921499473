.icon{
    color: var(--black);
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    transition: all .4s;
    border-radius: 4px;
}
.icon:hover{
    background-color: var(--primary-color);
    color: var(--white);
    transition: all .4s;
}