.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--grey);
}
.container img {
  height: 12rem;
}
.container h5 {
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
}
.container p {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}
