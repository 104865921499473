.select_box {
  align-self: flex-start;

  display: flex;
  gap: 1rem;
  align-items: center;
}
.select_box_btn,
.select_box_btn_selected {
  font-family: inherit;
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.15);
  padding: 0.3rem 1rem;
  cursor: pointer;
  transition: all 0.4s;
}
.select_box_btn {
  color: var(--primary-color);
  background-color: transparent;
}
.select_box_btn_selected {
  color: var(--white);
  background-color: var(--primary-color);
}
.select_box_btn:hover {
  transform: translateX(0.2rem);
  color: var(--red);
  border: 1px solid var(--red);
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
}
.select_box_btn_selected:hover {
  transform: translateX(0.2rem);
  background-color: var(--red);
  border: 1px solid var(--red);
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.1);
}
