.inputBox {
	margin: .5rem 0;
	background-color: transparent;
	border: 1px solid #d0d0d0;
	border-radius: 1rem;
	display: flex;
	align-items: center;
	padding: 0 1rem;
	transition: all .4s;
}

.inputBox:hover,
.inputBox:focus {
	border: 1px solid var(--white);
}
.textInput__input {
	padding: 1rem 0;
	width: 100%;
	border: none;
	background-color: rgba(0, 0, 0, 0);
	outline: none;
	font-family: inherit;
	font-size: 1.2rem;
	color: var(--white);
}
.errorText {
	color: red;
}

.textInput__label {
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--white);
}
.inputBox__icon {
	height: 2rem;
	width: 2rem;
	fill: var(--white);
	margin-right: 1rem;
}

@media screen and (max-height: 700px) {
	.inputBox {
		margin-top: 0;
		height: 31px;
		margin-bottom: 0;
	}

	input {
		height: 30px;
		background-color: transparent;
	}
}
