.navigation {
  background-color: var(--primary-color);
  margin: 1.5rem 0;
  margin-left: 1.5rem;
  border-radius: 2rem;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.navigation__list {
  list-style: none;
}
.navigation__logo {
  /* margin-top: 2rem; */
  /* height: 2rem; */
  max-width: 13rem;
  max-height: 5rem;
}
.navigation__logo_client {
  font-size: 1.2rem;
}
.navigation__list--item {
  margin-top: 2rem;
}
.navigation__list--link,
.navigation__list--link-active {
  text-decoration: none;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.navigation__list--link {
  color: var(--light-grey);
}
.navigation__list--link-active {
  color: var(--white);
}
.navigation__icon,
.navigation__icon-active {
  height: 3rem;
  width: 3rem;
}
.navigation__icon {
  fill: var(--light-grey);
}
.navigation__icon-active {
  fill: var(--white);
}

.powered__text {
  margin-top: 2rem;
  color: var(--light-grey);
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
}
.powered__text span {
  display: block;
  text-transform: uppercase;
}
.powered__text span a {
  color: currentColor;
  text-decoration: none;
  font-size: 1.2rem;
}
