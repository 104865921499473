.button {
	background-color: var(--primary-color);
	border: 1px solid var(--primary-color);
	font-family: inherit;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--white);
	padding: 1rem 2rem;
	border-radius: 1rem;
	cursor: pointer;
	transition: all .4s;

	display: flex;
	gap: .5rem;
	align-items: center;
}
.button_active {
	background-color: transparent;
	border: 1px solid var(--white);
	font-family: inherit;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--white);
	padding: 1rem 2rem;
	border-radius: 1rem;
	cursor: pointer;
	transition: all .4s;

	display: flex;
	gap: .5rem;
	align-items: center;
}
.button span {
	height: 2px;
	width: 2.5rem;
	background-color: var(--white);
	transition: all .4s;
}
.button:hover {
	background-color: transparent;
	border: 1px solid var(--white);
}
/* .button:hover span {
	background-color: var(--white);
} */
