.closeIconContainer {
	width: 100%;
	margin-top: 6px;
	display: flex;
	justify-content: flex-end;
}

.group_chat_close {
	cursor: pointer;
	height: 3rem !important;
	width: 3rem !important;
}

.iconAvatarContainer {
	padding: 1.4rem;
	background: var(--primary-color);
	display: inline-block;
	border-radius: 50%;
}

.iconAvatarIcon {
	width: 6.4rem;
	height: 4.8rem;
}

.iconContainer {
	display: flex;
	margin-top: -16px;
	justify-content: center;
}

.groupName {
	display: block;
	color: var(--primary-color);
	text-align: center;
	margin-top: 1rem;
	font-size: 1.6rem;
	font-weight: 600;
}

.group_name_edit_icon {
	height: 3rem;
	width: 3rem;
	display: inline-block;
	user-select: none;
	cursor: pointer;
	margin-bottom: -24px;
}

.icon_name_container {
	display: flex;
	justify-content: center;
	gap: 1rem;
	align-items: center;
}

.groupNameInput {
	display: flex;
	width: 100%;
	justify-content: center;
}

.groupNameEdit {
	display: flex;
	margin-left: 4rem;
	justify-content: center;
}

.modal {
	background-color: white;
	padding: 4px 8px;
	border-radius: 2.4rem;
	width: 370px;
	height: 560px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.addModal {
	background-color: white;
	padding: 4px 8px;
	border-radius: 2.4rem;
	position: relative;
	width: 420px;
	height: 545px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	overflow-y: scroll;
}

.input {
	width: 90%;
	align-self: center;
}

.inputContainer {
	justify-content: center;
	margin-top: 12px;
	display: flex;
}

.paricipantsContainer {
	display: flex;
	padding: 12px 18px 0px;
	justify-content: space-between;
}

.participants_tag {
	color: #3c0016;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 14px;
	font-family: Poppins;
	font-weight: 600;
}
.participants_tagnew {
	color: #3c0016;
	background: none;
	user-select: none;
	border: none;
	font-size: 14px;
	font-family: Poppins;
	font-weight: 600;
}

.participants_tag:hover {
	color: #b30d4a;
	font-family: Poppins;
}

.circularLoader {
	width: 100%;
	justify-content: center;
	display: flex;
	margin-top: 1rem;
}

.participants_tag1 {
	color: #3c0016;
	font-size: 14px;
	cursor: pointer;
	user-select: none;
	align-items: center;
	font-weight: 600;
}

.participants_tag3 {
	color: #3c0016;
	font-size: 13px;
	cursor: pointer;
	user-select: none;
	align-items: center;
	font-weight: 600;
}

.participants_tag2 {
	color: #3c0016;
	font-size: 18px;
	cursor: pointer;
	user-select: none;
	margin-top: -6px;
	margin-right: 6px;
	align-items: center;
	font-weight: bold;
}

.cardList {
	overflow-y: auto;
	scrollbar-color: #3c0016;
	scrollbar-arrow-color: #3c0016;
	scrollbar-base-color: #3c0016;
}

.buttonContainer {
	justify-content: flex-end;
	display: flex;
	padding-right: 0.4rem;
	margin-top: 0.8rem;
}

.leaveGroup {
	align-items: center;
	cursor: pointer;
	background: none;
	display: flex;
	border: none;
	gap: 0.6rem;
}

@media (max-width: 1200px) {
	.addModal {
		height: 400px;
	}
}
