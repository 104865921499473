.transcriptCards {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}
.transcriptCard {
  width: 20%;
  background-color: var(--white);
  border-radius: 1rem;
  padding: 2rem;
}
.transcriptCard_title {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  color: var(--grey);
}
.transcriptCard_title h3 {
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
}
.transcriptCard_title p {
  background-color: var(--primary-color-light);
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  font-weight: 600;
  /* text-transform: lowercase; */
}
.transcriptCard_content {
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--light-grey);

  font-size: 1.5rem;
  font-weight: 600;
}
.transcriptCard_details {
  font-family: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: underline;
  font-size: 1.2rem;
  color: var(--primary-color);
}
.transcriptCard_btns {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.transcriptData {
  margin: 3rem 0;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.transcriptData__user {
  display: grid;
  gap: 1rem;
  align-items: flex-start;
  grid-template-columns: 15% 85%;
}
.transcriptData__user h3 {
  font-size: 1.3rem;
}
.transcriptData__user_text {
  font-size: 1.2rem;
  max-height: 20rem;
  overflow: auto;
}
.transcriptData__user_btn {
  background-color: rgba(60, 0, 22, 0.8);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: none;
  font-family: inherit;
  cursor: pointer;
  margin-left: 1rem;

  /* position: absolute;
  top: 0rem;
  left: 0; */

  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.transcriptData__user_text:hover .transcriptData__user_btn {
  opacity: 1;
  visibility: visible;
}

.transcriptDataEdit,
.transcriptDataEdit div {
  display: flex;
  gap: 0.5rem;
}
.transcriptDataEdit {
  flex-direction: column;
}
.transcriptDataEdit div {
  margin-top: 1rem;
  justify-content: flex-end;
}
.transcriptDataEditSave,
.transcriptDataEditClose {
  font-family: inherit;
  font-weight: 600;
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.transcriptDataEditSave {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.transcriptDataEditClose {
  background-color: var(--orange);
  border: 1px solid var(--orange);
}
.transcriptDataEdit_textBox {
  font-family: inherit;
  line-height: inherit;
  background-color: rgb(248, 248, 248);
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1rem;
  border-radius: 1rem;
  outline: none;
}
