.generalButton {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #1e90ff; /* Default blue background */
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s, background-color 0.3s;
}

.generalButton:hover {
    transform: scale(1.1);
    background-color: #1c86ee; /* Slightly darker blue on hover */
}

.generalButton i {
    font-size: 24px;
    color: white;
}

.green {
    background-color: #0070ff;
}

.orange {
    background-color: #0070ff;
}

.red {
    background-color: #f00;
}

.theme {
   background-color:  rgba(60, 0, 22, 0.51);
} 
