.quill {
	background-color: var(--white);
	border-radius: 1.5rem;
	border: 1px solid var(--light-grey);
}
.ql-container {
	/* font-family: inherit !important; */
	height: max-content !important;
	min-height: 10rem;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
	border: none !important;
	/* border-radius: 1.5rem !important; */
}
.ql-toolbar.ql-snow {
	border: none !important;
	border-bottom: 1px solid var(--light-grey) !important;
	/* border-radius: 1.5rem !important; */
}
