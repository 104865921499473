.container {
  display: grid;
  grid-template-columns: 16rem repeat(1, 1fr);
  gap: 2rem;
  height: 100vh;
  width: 100%;
  transition: all 0.5s ease;
}
.flex {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
.dangerText {
  color: var(--red);
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: 600;
}
.primaryText {
  color: var(--primary-color);
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: 600;
}
.primaryText_note {
  color: var(--primary-color);
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 500;
}
.primaryText_note_bottom {
  margin-top: 1rem;
  border-top: 1px solid var(--light-grey);
  padding-top: 1rem;

  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.primaryText_note_date {
  color: var(--grey);
  font-size: 1.1rem;
  line-height: 1.4;
  font-weight: 500;
}
.primaryText_note_type {
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--orange);
  color: var(--white);
  padding: 0.3rem 1rem;
  border-radius: 1rem;
}
.primaryText_note_btn {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  color: var(--white);
  font-family: inherit;
  font-size: 1.2rem;
  padding: 0.4rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s;
}
.primaryText_note_btn svg {
  height: 1.3rem;
  width: 1.3rem;
  fill: var(--white);
  transition: all 0.4s;
}
.primaryText_note_btn:hover {
  background-color: transparent;
  color: var(--primary-color);
}
.primaryText_note_btn:hover svg {
  fill: var(--primary-color);
}
.scrollContainer {
  max-height: 65vh;
  overflow: auto;
  padding-right: 1rem;
  margin-bottom: 1rem;
}
.noContent_table_row {
  border-bottom: none !important;
}
.noContent_table_row:hover {
  background-color: transparent !important;
}
.table__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.table__footer_text {
  font-size: 1.2rem;
  font-weight: 600;
}

/* ============================================Toggle CSS Start============================================ */

.switch {
  position: relative;
  display: inline-block;
  min-width: 3.5rem;
  height: 2.1rem;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8b8b8b;
  box-shadow: 0.5rem 0.2rem 1rem 2px rgb(0 0 0 / 14%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 1.4rem;
  width: 1.4rem;
  left: 4px;
  bottom: 4px;
  background-color: var(--light-grey);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbox {
  opacity: 0;
}

.checkbox:checked + .slider {
  background-color: var(--primary-color);
}

.checkbox:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.checkbox:checked + .slider:before {
  background-color: #e7e7ea;
  -webkit-transform: translateX(1.3rem);
  -ms-transform: translateX(1.3rem);
  transform: translateX(1.3rem);
}

.slider.round {
  border-radius: 3.4rem;
}

.slider.round:before {
  border-radius: 50%;
}

/* ============================================Toggle CSS End============================================ */

/* ============================================Pagination CSS Start============================================ */

.paginationActive {
  background-color: var(--primary-color);
}

.paginationActive a {
  color: #e7e7ea !important;
}

.paginationBttns {
  list-style: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.paginationBttns li {
  height: 3rem;
  width: 3rem;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  transition: all 0.4s;
  overflow: hidden;
}

.paginationBttns a {
  padding: 1rem;
  color: var(--primary-color);
  line-height: 1;
  cursor: pointer;
  transition: all 0.4s;
}

.paginationBttns li:hover {
  color: #e7e7ea;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}

.paginationBttns a:hover {
  color: #e7e7ea;
}

/* ============================================Pagination CSS End============================================ */

/* ============================================Context Menu CSS Start============================================ */
nav.react-contextmenu {
  position: fixed;
  width: 20rem;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 1rem;
  background-color: var(--white);
  padding: 1rem 0;
  overflow: hidden;
  z-index: 99;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
}
.react-contextmenu .react-contextmenu-item:hover {
  background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 1.3rem;
  display: block;
  text-decoration: none;
  padding: 1rem 1.5rem;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}
.react-contextmenu-item span {
  margin-left: 1rem;
}
.react-contextmenu-item .copy {
  fill: skyblue;
  height: 1.5rem;
  width: 1.5rem;
}
.react-contextmenu-item .delete {
  fill: red;
  height: 1.5rem;
  width: 1.5rem;
}
.react-contextmenu-item .send {
  fill: blue;
  height: 1.5rem;
  width: 1.5rem;
}
.react-contextmenu-item .share {
  fill: green;
  height: 1.5rem;
  width: 1.5rem;
}
.react-contextmenu-item .watchlist {
  fill: rebeccapurple;
  height: 1.5rem;
  width: 1.5rem;
}
/* ============================================Context Menu CSS End============================================ */

.go4109123758 {
  font-size: 1.2rem;
}
.Toastify__toast-body {
  z-index: 100000;
  font-size: 1.5rem;
}

/* .Toastify__toast--success {
	background-color: var(--primary-color) !important;
}

.Toastify__toast--error {
	background-color: var(--red) !important;
} */

.dot-stretching,
.dot-stretching_dark {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  transform: scale(1.25, 1.25);
  animation: dotStretching 2s infinite ease-in;
}
.dot-stretching {
  background-color: var(--white);
  color: var(--white);
}
.dot-stretching_dark {
  background-color: var(--green);
  color: var(--green);
}

.dot-stretching::before,
.dot-stretching::after,
.dot-stretching_dark::before,
.dot-stretching_dark::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-stretching::before,
.dot-stretching_dark::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  animation: dotStretchingBefore 2s infinite ease-in;
}

.dot-stretching::before {
  background-color: var(--white);
  color: var(--white);
}
.dot-stretching_dark::before {
  background-color: var(--orange);
  color: var(--orange);
}

.dot-stretching::after,
.dot-stretching_dark::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--white);
  color: var(--white);
  animation: dotStretchingAfter 2s infinite ease-in;
}
.dot-stretching::after {
  background-color: var(--white);
  color: var(--white);
}
.dot-stretching_dark::after {
  background-color: var(--light-red);
  color: var(--light-red);
}

@keyframes dotStretching {
  0% {
    transform: scale(1.25, 1.25);
  }
  50%,
  60% {
    transform: scale(0.8, 0.8);
  }
  100% {
    transform: scale(1.25, 1.25);
  }
}

@keyframes dotStretchingBefore {
  0% {
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    transform: translate(-20px) scale(1, 1);
  }
  100% {
    transform: translate(0) scale(0.7, 0.7);
  }
}

@keyframes dotStretchingAfter {
  0% {
    transform: translate(0) scale(0.7, 0.7);
  }
  50%,
  60% {
    transform: translate(20px) scale(1, 1);
  }
  100% {
    transform: translate(0) scale(0.7, 0.7);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--black);
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
}
