.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.logo {
  height: 5rem;
}
.zipIcon {
  height: 5rem;
  width: 5rem;
  /* fill: var(--black); */
  fill: none;
}
.fileName {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
}
.date {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
}
