.loginContainer_textBox {
	display: flex;
	gap: .5rem;
	flex-direction: column;
}
.loginContainer_textBox_label {
	font-size: 1.3rem;
	color: var(--white);
	font-weight: 500;
}
.loginContainer_textBox_label_dark {
	font-size: 1.3rem;
	color: var(--black);
	font-weight: 500;
}
.loginContainer_textBox_inputBox {
	display: flex;
	gap: 1rem;
	align-items: center;
	background-color: var(--white);
	border-radius: 1rem;
	padding: 1rem 2rem;
}
.loginContainer_textBox_inputBox_dark {
	display: flex;
	gap: 1rem;
	align-items: center;
	background-color: var(--light-grey);
	border-radius: 1rem;
	padding: 1rem 2rem;
}
.loginContainer_textBox_icon {
	height: 1.8rem;
	width: 1.8rem;
	color: var(--primary-color);
}
.loginContainer_textBox_input {
	background-color: transparent;
	border: none;
	outline: none;
	font-family: inherit;
	font-size: 1.3rem;
	font-weight: 600;
	color: var(--black);
	width: 90%;
}
.loginContainer_textBox_icon_check {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--green);
}
.loginContainer_textBox_error {
	color: var(--light-red);
	font-weight: 600;
	font-size: 1.2rem;
}
.loginContainer_textBox_show_password {
	border: none;
	background-color: transparent;
	cursor: pointer;

	display: flex;
	align-items: center;
}
.loginContainer_textBox_show_password_icon {
	height: 1.8rem;
	width: 1.8rem;
	fill: var(--grey);
}
