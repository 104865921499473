/* AIAssistantModal.css */
.modalOverlay {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  
  .modalContent {
    background: #BA738D;
    width: 38rem;
    border-radius: 1.9rem;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .profile {
    display: flex;
    gap: 10px;
    background-color: rgba(60, 0, 22, 0.51);
    padding: 1rem 2rem;
    border-radius: 2rem;
    height: 11rem;
  }

  .audioText{
    font-size: 1.8rem;
  color: #fff;
  font-weight: lighter;
  text-align: left;
  }
  
  .profileIcon {
    width: 7rem;
    height: 6rem;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    color: #fff;
  }
  
  .assistantHeader {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .voiceIcon {
    font-size: 20px;
  }
  
  .summaryButton {
    margin-top: 20px;
    background-color: #b08989;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
  }
  