.content {
  background-color: var(--white);
  border-radius: 2rem;
  padding: 2rem 0;

  /* height: 75%; */
  overflow: auto;
}
.filters {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  border-radius: 2rem;
  padding: 2rem;
  margin-bottom: 1rem;
}

.search_calandar_container {
  display: flex;
  gap: 1rem;
}

.calander_items {
  display: flex;
  gap: 2rem;
}

.search_Button {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  background: var(--primary-color);
  color: white;
}

.calander_container {
  border-bottom: 1px solid var(--light-grey);
  padding-bottom: 0.5rem;
}

.search_title {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  /* margin-left: 0.5rem; */
  font-weight: 600;
  color: var(--grey);
  display: block;
}

.search_calandar_container {
  display: flex;
  gap: 4.5rem;
}

.headerTextInput {
  background-color: var(--white);
  padding: 0 0.5rem;
  height: 3rem;
  border: 1px solid var(--light-grey);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.4s;
}

.logs {
  height: 57.5vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.log {
  padding: 0 3rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--light-grey);

  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.log p {
  font-size: 1.3rem;
  font-weight: 600;
}
.log p span {
  text-transform: uppercase;
  color: var(--primary-color);
  font-weight: 700;
}
.log button {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  font-family: inherit;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 0.5rem 3rem;
  color: var(--primary-color);
  border-radius: 0.5rem;
  transition: all 0.4s;
}
.log button:hover {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--white);
}
.logPagintation {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.selectBoxContainer {
  background-color: #f1f1f1;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
}
.selectBox {
  font-family: inherit;
  background-color: transparent;
  border: none;
  outline: none;
}
.InputBox {
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.7rem 2rem;
}
.InputBox input {
  font-family: inherit;
  font-weight: 600;
  font-size: 1.3rem;
  background-color: transparent;
  border: none;
  outline: none;
}
.InputBox svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);
}
.logData {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--grey);
}
/* .logData div {
  display: flex;
  gap: 1rem;
  align-items: center;
} */
.logData div p span {
  color: var(--primary-color);
}

.filter_container {
  background-color: var(--white);
  border-radius: 2rem;
  padding: 2rem;
  margin-bottom: 1rem;
}
.filter_text_box,
.filter_text_box_input {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.filter_text_box_label {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--black);
  min-width: 6rem;
}
.filter_text_box_input {
  background-color: #f1f1f1;
  padding: 0.7rem 1rem;
  border: 1px solid var(--light-grey);
  border-radius: 1rem;
  width: 15rem;
}
.filter_text_box_input_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--black);
}
.filter_text_box_input_tag {
  background-color: transparent;
  font-family: inherit;
  font-size: 1.3rem;
  color: var(--black);
  width: 100%;
}
.filter_text_box_input_tag::placeholder,
.filter_text_box_input_tag:-ms-input-placeholder,
.filter_text_box_input_tag::-webkit-input-placeholder {
  color: var(--light-grey);
  opacity: 1;
}
.filter_text_box_select_box {
  background-color: #f1f1f1;
  padding: 0 1rem;
  border: 1px solid var(--light-grey);
  border-radius: 1rem;
  width: 15rem;
}
.filter_text_box_select {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0.5rem 0;
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
  color: var(--black);
  width: 100%;
}
.filter_text_box_date {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0.5rem 0;
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
  color: var(--black);
  width: 100%;
}
.filter_tags_box {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}
.tag_button_active,
.tag_button {
  font-family: inherit;
  font-size: 1.1rem;
  border: 1px solid var(--primary-color);
  padding: 0.3rem 1.2rem;
  border-radius: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.4s;
}
.tag_button_active {
  background-color: var(--primary-color);
  color: var(--white);
}
.tag_button {
  background-color: transparent;
  color: var(--primary-color);
}
.tag_button_active:hover,
.tag_button:hover {
  transform: scale(1.1);
}
