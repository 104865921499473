.button {
	border: none;
	padding: 0.8rem 2rem;
	border-radius: 0.6rem;
	font-family: inherit;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--red);
	transition: all .3s;
	cursor: pointer;
	background-color: transparent;
}

.button:hover{
	border: 1px solid red;
	background: white;
}