.content {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}
.left_content {
  max-width: 70%;
  min-width: 58vw;
  height: 80vh;
  overflow: auto;
}
.imageFile {
  max-width: 100%;
  min-width: 58vw;
  border-radius: 2rem;
  object-fit: fill;
}
.card_pdf_frame {
  width: 58vw;
  height: 98%;
  border-radius: 2rem;
  border: none;
}
.card_video {
  width: 58vw;
  border-radius: 2rem;
}
.right_content {
  max-width: 30%;
  background-color: var(--white);
  padding: 2rem;
  border-radius: 2rem;
}
.right_content_title {
  font-size: 1.5rem;
  font-weight: 600;
}
.right_content_desc {
  font-size: 1.3rem;
  font-weight: 500;
}

.no_preview {
  background-color: var(--white);
  height: 80vh;
  width: 58vw;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}
.no_preview img {
  height: 10rem;
}
.no_preview p {
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--grey);
}
