.form_input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* align-items: flex-start; */
}
.form_input__label {
  color: var(--primary-color);
  font-size: 1.2rem;
}
.form_input__container {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--white);
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
  padding: 2rem;
  /* padding-left: 1rem; */
  min-width: 66.5rem;
}
.form_range_value {
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
  padding: 1.7rem 2rem;
  min-width: 10.5rem;
}
.form_range_value p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
}
.form_input__icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);
}
.form_input__box {
  font-family: inherit;
  background-color: transparent;
  color: var(--grey);
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}
.form_input__box::-webkit-input-placeholder {
  color: var(--light-grey);
}
.form_input__error {
  font-size: 1.2rem;
  color: #ff3366;
}
