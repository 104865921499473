.textBox {
	background-color: var(--white);
	border-radius: 1.5rem;
	border: 1px solid var(--light-grey);
}
.textInput {
	width: 100%;
	font-family: inherit;
	font-size: 1.3rem;
	font-weight: 500;
	padding: 2rem;
	background-color: transparent;
	border: none;
	outline: none;
}
.textInput::-webkit-input-placeholder {
	color: var(--light-grey);
}
