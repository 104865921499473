.sharedScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #eaf4ff;
  border: 1px solid #007bff;
}

.sharedContent {
  width: 90%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px dashed #007bff;
  font-size: 1.2rem;
  color: #333;
}
