.subject {
    /* margin-top: 2.5rem; */
    width: 100%;
    height: 5rem;
    background-color: var(--white);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    
}

.subject h2 {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding-left: 2rem;
}

.emailBody{
    width: 100%;
    height: 75vh;
    background-color: var(--white);
    border-radius: 1rem;
    padding: 2rem;
    overflow: scroll;
}

.content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom:  1px solid rgb(150, 150, 150);
}
.userDetails{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.user_image{
    width: 5.5rem;
    height: 5.2rem;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}
.mail_recipents{
    display: flex;
    flex-direction: column;
    align-items:  flex-start;
    font-size: 10px;
    font-weight: 400;
}

.mail_recipent{
    font-size: 10px;
    font-weight: 400;
    color : var(--grey)
}
.actions{
    display: flex;
    flex-direction: row ;
    align-items: center;
    gap: 0.4em;
}
.trash{
    color: var(--black);
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    transition: all .4s;
    border-radius: 4px;
}
.trash:hover{
    background-color: var(--primary-color);
    color: var(--white);
    transition: all .4s;
}
.attachment{
    border-bottom: 1px solid gray;
    padding : 1rem
}
.attachmentCount{
    font-size: 15px;
    font-weight: 600;
    color: var(--grey);
}
.attachmentImages{
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.attachmentContainer{
    position: relative;
    display: inline-block;
}

.attachmentFileName {
    position: absolute;
    bottom: 7px;
    left: 0;
    background-color: rgba(0, 0, 0 , 0.5); /* Semi-transparent white background */
    padding: 5px;
    margin: 0;
    width: 100%;
    color: var(--white);
    font-weight: 300;
    font-size: 10px;
    box-sizing: border-box;
    text-align: center;
}

.emailBody{
    height: max-content;
    font-size: 15px;
    font-weight: 500;
    text-align: left;

}
.noContent{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column ;
}
.noContentText{
    font-size: 2rem;
    font-weight: 600;
    color: var(--grey);
}