.button,
.button_search,
.button_search_clear {
  font-family: inherit;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  padding: 1rem 2.5rem;
  color: var(--white);
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}
.button_search,
.button_search_clear {
  padding: 1rem 1rem;
}
.button_search_clear {
  background-color: var(--red);
  border: 1px solid var(--red);
}
.button_search:disabled,
.button_search_clear:disabled {
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  cursor: not-allowed;
}
.button_search:disabled:hover,
.button_search_clear:disabled {
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
}
.button_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
  transition: all 1s;
}

.button:hover,
.button_search:hover {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.button_search_clear:hover {
  background-color: transparent;
  border: 1px solid var(--red);
  color: var(--red);
}
.button_search:hover .button_icon {
  fill: var(--primary-color);
}
.button_search:disabled:hover .button_icon {
  fill: var(--white);
}
.button_search_clear:hover .button_icon {
  fill: var(--red);
}

.button:hover .iconDash {
  stroke: var(--primary);
}

.iconDash {
  width: 2rem;
  height: 2px;
  stroke: #fff;
  margin-left: 5px;
}

@media screen and (max-height: 768px) {
  .button {
    height: 31px;
  }
}
