.input{
  border: none;
  padding: 1rem 2rem;
  text-align: center;
  color: var(--primary-color);
  font-weight: 700;
  font-family: 'Raleway', sans-serif;
  border-style: none;
  max-width: 100%; 
  font-size: 1.4rem;
  transition: width 0.2s ease-in-out; 
  border-bottom: 0.2px solid #97979735;
  outline: none;
  caret-color: #979797;
  caret-shape: 20px;
}

.input::placeholder{
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
  color: #979797;
  font-size: 1.4rem;
  text-align: center;
}

.group_name_edit_icon {
  height: 3rem;
  width: 3rem;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  margin-bottom: -24px;
}

.inputContainer{
  max-width: 90%;
  display: flex;
  align-items: center;
}