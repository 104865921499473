.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #f4f4f6;
    border-top: 1px solid #e0e0e0;
  }
  
  .notesToggle {
    background-color: #007aff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .notesToggle:hover {
    background-color: #005bb5;
  }

  .groupVideoCall{
    display: flex;
    position: relative;
    bottom: 7rem;
    justify-content: center;
    gap: 4rem;
  }

  .videoButton Button {
display: flex;
position: absolute;
  }

  .videoButtonIcon {
  position: relative;
  width: 3rem;
  bottom: 5rem;
  left: 1rem;
  
  }

  .microphoneButton Button{
    display: flex;
    position: absolute;
  }

  .microphoneButtonIcon{
    position: relative;
    width: 3rem;
    bottom: 5rem;
    left: 1rem;
  }

  .callEndButton Button {
    display: flex;
    position: absolute;
  }

  .callEndButtonIcon{
    position: relative;
    width: 3rem;
    bottom: 5rem;
    left: 1rem;
  }

  .centerButtons{
    display: flex;
    justify-content: center;
    gap: 4rem;
  }

  .footerLeft{
    display: flex;
  margin-right: -16rem;
  gap: 3rem;
  }

  .miniPlayerButton{
    position: relative;
    right: 79rem;
  }

  .miniPlayerIcon{
    width: 4rem;
    height: 4rem;
    margin-bottom: -1rem;
    cursor: pointer;
  }

  .iconName{
    color: #fff;
  font-size: 1.2rem;
  margin-top: 0rem;
  margin-right: -3rem;
  margin-left: -1rem;
  }


  .addParticipantsIcon{
    color: #fff;
    font-size: 1.2rem;
    margin-top: 0rem;
    margin-left: -1rem;
    display: flex;
  }

  .screenShareIcon{
    color: #fff;
    font-size: 1.2rem;
    margin-top: 0rem;
    margin-left: -1rem;
    display: flex;
  }
  