.card{
    border-style: none;
    border-radius: 1rem;
    width: 95%;
}
.userCard{
  background-color: transparent;
  outline: none;
  border-radius: 10px;
  /* border-style: none; */
  padding: 1rem 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  /* justify-content: space-between; */
  /* position: relative; */
}
.active{
  transition: transform 0.3s ease-in-out;
  background-color: rgba(60, 0, 22, 0.1);
}
.user_image{
  background-color: var(--white);
  box-shadow: 0px 0px 0.5rem 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
}
.userImageBox{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.user_attachment{
    position: relative;
    fill: var(--grey);
    left : 1rem;
    /* margin-top: 1rem; */
    /* top: 19%; */
}
.name{
    font-size: 15px;
    color: var(--black);
}
.nameUnread{
    font-size: 15px;
    color: var(--primary-color);
}

.emailTexts{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin: 1rem 0rem;
    width: 100%;
}
.emailText{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    /* justify-content: flex-start; */
}
.subjects{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
}
.subject{
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 600;
    /* width: auto; */
}
.subjectUnread{
    font-size: 12px;
    font-weight: 600;
    color: var(--primary-color);
}
.emailBody{
    display: flex;
    width: 100%;
    font-size: 10px;
    font-weight: 500;
    color: var(--grey);
   text-align: left;
   white-space: normal;
}
.bodyText{
    width: 93%;
    display: flex;
    flex-direction: row;
}
.mailTime{
    font-size: 10px;
    font-weight: 400;
    color: var(--grey);
}

.unread{
    /* position: absolute; */
    background-color: #F2E9E1;
    border-left: 7px solid #FF9900;
}
.Emailread{
    background-color: var(--white);
    /* border-style: none; */
    /* borde: 7px solid transparent; */
}
.mailTimeUnread{
    font-size: 10px;
    font-weight: 400;
    color: #FF7B01;
}

.icon{
    display: flex;
    align-items: center;
    color: var(--primary-color);
    margin: 0px 8px;
}

.icons{
    margin-top: 1rem;
}

.content{
    display: flex;
    justify-content: space-between;
}
.actions{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}