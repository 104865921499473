:root {
	--bg-color: #f2f8ff;
	--primary-color: #3c0016;
	--primary-color-light: #f1b4ca;
	--grey: #5e5e5e;
	--light-grey: #dddddd;
	--white: #ffffff;
	--black: #000000;
	--green: #4ed28b;
	--light-red: rgb(253, 124, 124);
	--red: #ff0000;
	--orange: #ff7b01;
	--light-orange: #fff1e0;
}
* {
	margin: 0px;
	padding: 0px;
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
}

body {
	box-sizing: border-box;
	background-color: var(--bg-color);
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	line-height: 1.7;
}

::-webkit-scrollbar {
	width: .5rem;
	height: .5rem;
}
::-webkit-scrollbar-thumb {
	background-color: var(--primary-color);
	border-radius: 10px;
	position: absolute;
	right: 10px;
}
::-webkit-scrollbar-track {
	background-color: transparent;
}
