.addParticipantModal{
    background-color: #fff;
    width: 44rem;
    height: 65rem;
    margin-left: 57rem;
    margin-bottom: 0rem;
    border: 1px solid;
    border-radius: 3rem;
    position: fixed; /* Ensure the modal stays on top of everything */
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%); /* Center the modal horizontally and vertically */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    z-index: 1001; /* Ensure it appears above other components */
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1000; /* Below the modal but above other content */
}

.groupCallLogo {
    background-color: #3B0016;
    width: 10rem;
    height: 10rem;
    border-radius: 6rem;
    display: flex;
    margin-left: 16rem;
    margin-top: 3rem;
}

.groupCallIcon {
    width: 7rem;
    margin-left: 1.3rem;
}

.groupName{
    font-size: 1.8rem;
  color: #3B0016;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  margin-top: 1rem;
}

.onCallText{
    font-size: 1.2rem;
    color: #3B0016;
    margin-left: 3rem;
    font-weight: 600;
}

.participant1{
    display: flex;
}

.profile{
    display: flex;
    padding: 1rem;
    border-radius: 2rem;
    margin-left: 1rem;
}

.profileIcon {
    width: 7rem;
    height: 6rem;
  }

  .participantCard {
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    display: flex;
    margin-top: 2rem;
    height: 5rem;
    width: 31rem;
}

.avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.info {
    
}

.name {
    display: block;
    font-weight: revert;
    font-size: 1.2rem;
    color: #3B0016;
}

.email {
    color: #888;
    font-size: 1.2rem;
}

.icons{
    display: flex;
    gap: 2rem;
}

.icon {
    width: 2.2rem;
}

.iconClose{
    width: 2rem;
    height: 2rem;
}

