.pressable_button {
  background-color: #3c0016;
  color: #ffffff;
  padding: 8px 20px;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  border-radius: 10px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  transition: background-color 0.2s ease;
}

.pressed {
  background-color: #6f0032;
}

.disabled{
  opacity: 20%
}