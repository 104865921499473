.inputContainer {
  border-bottom: 1px solid #5e5e5e4f;
  padding: 0.4rem 0rem 0.8rem;
  display: flex;
  align-items: center;
  width: 60%
}
.container {
}

.inputLabel{
  color: #3B0016;
  font-size: 1.4rem;
  margin: 1.4rem 0rem 0.6rem;
  display: block;
}

.inputIcon{
  width: 3rem;
  margin: 0rem 0.8rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  height: 2rem;
  border-right: 1px solid #5e5e5e4f;
}

.inputEmailIcon{
  width: 3rem;
  margin: 0rem 0.8rem;
  padding-right: 1rem;
  height: 2rem;
  border-right: 1px solid #5e5e5e4f;
}

input {
  border: none;
  outline: none;
  width: 100%;
}
