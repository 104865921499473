.container {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 18rem minmax(max-content, 59vh);
}
.containerLeft {
  grid-column: 1 / 3;
  grid-row: 1 / -1;

  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.folderContainer {
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3rem;
  overflow: hidden;
  transition: all 0.4s;
}
.folderHeading {
  font-size: 1.7rem;
  font-weight: 600;
}
.folders {
  padding-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  max-height: 12rem;
  overflow: auto;
}
.folders_files {
  padding-top: 1rem;
  /* padding-bottom: 2rem; */
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 28rem;
  overflow: auto;
}
.folder,
.folderSelected {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: inherit;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 19.5rem;
  cursor: pointer;
  transition: all 0.4s;
}
.addFolder {
  display: flex;
  align-items: center;
  font-family: inherit;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 19.5rem;
  cursor: pointer;
  transition: all 0.4s;
}
.folderBox {
  position: relative;
}
.folderBox:hover .folderFullName {
  display: block;
}
.folderFullName {
  display: none;
  z-index: 100;
  position: absolute;
  top: -2rem;
  left: 0rem;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  color: var(--white);
  background-color: var(--primary-color);
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
}
.folder {
  gap: 1rem;
  justify-content: space-between;
  border: 1px solid var(--light-grey);
  background-color: var(--light-grey);
}
.folderSelected {
  gap: 1rem;
  justify-content: space-between;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
}
.folderLeft {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.folderIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--orange);
}
.folderSelected .folderIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--white);
}
.folderName {
  font-size: 1.2rem;
  font-weight: 600;
}
.folderSelected .folderName {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--white);
}
.folderfiles {
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  text-align: left;
  border-top: 1px solid var(--white);
  margin-top: 0rem;
  padding-top: 0.5rem;
}
.folderSelected .folderfiles {
  font-size: 1rem;
  font-weight: 600;
  color: var(--white);
}
.folder:hover,
.file:hover,
.recentUpload:hover {
  border: 1px solid var(--grey);
}
.addFolder {
  gap: 0.5rem;
  justify-content: center;
  border: 1px solid var(--primary-color-light);
  background-color: var(--primary-color-light);
}
.addFolderIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--primary-color);
}
.addFolderName {
  font-size: 1.2rem;
  font-weight: 800;
  color: var(--primary-color);
}
.addFolder:hover {
  transform: translateX(0.5rem) scale(1.02);
}

.folderFiles {
  /* grid-column: 1 / 3;
  grid-row: 2/-1; */
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3rem;
  height: 59vh;
  overflow: auto;
}
.topsubfolderFIles {
  max-height: 19rem;
  overflow: hidden;
  transition: all 0.4s;
}
.fileupload_progress {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;
}
.fileupload_progress_text {
  font-size: 1.3rem;
  font-weight: 600;
  color: car(--grey);
}
.fileupload_progress_bar {
  height: 1rem;
  width: 100%;
  background-color: var(--light-grey);
  border-radius: 2rem;
  overflow: hidden;
}
.fileupload_progress_bar div {
  height: 100%;
  background-color: var(--primary-color);
  transition: all 0.4s;
}
@media only screen and (max-width: 1400px) {
  .container {
    grid-template-rows: 18rem minmax(max-content, 50vh);
  }
  .folderFiles {
    height: 50vh;
  }
}
.folderFilesTop {
  display: flex;
  justify-content: space-between;
}
.folderFilesTopFlex {
  display: flex;
  align-items: center;
  gap: 1rem;
}
/* .uploadBtn {
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: all 0.4s;

  display: flex;
  gap: 0.5rem;
  align-items: center;
} */
.uploadBtn {
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: all 0.4s;

  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.uploadBtnIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--primary-color);
  transition: all 0.4s;
}
.uploadBtn:hover {
  background-color: var(--primary-color);
  color: var(--white);
  /* border-bottom: 1px solid var(--primary-color); */
}
.uploadBtn:hover .uploadBtnIcon {
  fill: var(--white);
}
.folderFilesSub {
  font-size: 1.3rem;
  font-weight: 600;
}
.file {
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  font-family: inherit;
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 10rem;
  height: 8rem;
  cursor: pointer;
  transition: all 0.4s;
  text-decoration: none;
  color: currentColor;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 0.5rem; */
  position: relative;
}
.fileListGridBtn {
  background-color: transparent;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  font-family: inherit;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.fileListGridBtnActive {
  background-color: var(--light-grey);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  font-family: inherit;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.fileListGridBtnIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);
}
.folders_filesList {
  padding-top: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  max-height: 28rem;
  overflow: auto;
}
.fileList {
  /* background-color: var(--light-grey); */
  border-bottom: 1px solid var(--light-grey);
  font-family: inherit;
  font-size: 1.2rem;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  width: 100%;
  cursor: pointer;
  transition: all 0.4s;
  text-decoration: none;
  color: currentColor;
  text-align: center;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  transition: all 0.4s;
}
.fileList:hover {
  background-color: var(--light-grey);
}
.fileIconList {
  height: 2rem;
  width: 2rem;
  /* fill: var(--orange); */
  fill: none;
}
.fileIcon {
  height: 3rem;
  width: 3rem;
  /* fill: var(--orange); */
  fill: none;
}
.fileNmme {
  font-size: 1.1rem;
  font-weight: 600;
}
.fileFullNmme {
  display: none;
  position: absolute;
  bottom: -3.5rem;
  left: 0;
  white-space: nowrap;
  background-color: var(--primary-color);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  min-width: 10rem;
  z-index: 100;
}
.file:hover .fileFullNmme {
  display: block;
}

.recentUploadsContainer {
  grid-column: 3/-1;
  /* grid-row: 1/3; */
  grid-row: 1/-1;
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3rem;
}
.recentUploads {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.recentUpload {
  display: flex;
  gap: 1rem;
  align-items: center;

  font-family: inherit;
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  border-radius: 1rem;
  padding: 0.6rem 1rem;
  cursor: pointer;
  transition: all 0.4s;

  text-decoration: none;
  color: currentColor;
}
.recentUploadIcon {
  height: 3.5rem;
  width: 3.5rem;
  /* fill: var(--orange); */
  fill: none;
}
.recentUploadData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.recentUploadName {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.recentUploadDate {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
  color: var(--grey);
}
.recentUploadDate span {
  background-color: var(--primary-color-light);
  padding: 0.1rem 0.5rem;
  border-radius: 0.5rem;
  color: var(--black);
}

.storageContainer {
  grid-column: 3/-1;
  grid-row: 3/-1;
  background-color: var(--white);
  border-radius: 3rem;
  padding: 2rem 3rem;
}
.storageContainerLines {
  margin-top: 1rem;
  position: relative;
  height: 1rem;
}
.storageContainerLineFull {
  position: absolute;
  height: 0.8rem;
  width: 100%;
  background-color: var(--light-grey);
}
.storageContainerLineUsed {
  position: absolute;
  height: 0.8rem;
  width: 80%;
  background-color: var(--red);
}
.storageContainerTitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--grey);
}
.storageContainerButton {
  margin-top: 0.5rem;
  background-color: transparent;
  border: brown;
  border-bottom: 1px solid transparent;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
  transition: all 0.4s;
}
.storageContainerButton:hover {
  border-bottom: 1px solid var(--primary-color);
}

.addFolderInput {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 19.5rem;

  padding: 0.55rem 1rem;
  border: 1px solid var(--light-grey);
  border-radius: 0.5rem;
}
.addFolderInputIcon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--primary-color);
}
.addFolderInputBox {
  font-family: inherit;
  font-size: 1.2rem;
  color: var(--primary-color);
  border: none;
  background-color: transparent;
  outline: none;
  width: 9.5rem;
}
.addFolderInputSubmitBtn,
.addFolderInputCloseBtn {
  border: none;
  cursor: pointer;
  padding: 0.6rem;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
}
.addFolderInputSubmitBtn {
  background-color: var(--primary-color);
}
.addFolderInputCloseBtn {
  background-color: var(--red);
}
.addFolderInputSubmitBtnIcon,
.addFolderInputCloseBtnIcon {
  height: 1.2rem;
  width: 1.2rem;
  fill: var(--white);
}

.advertise_right_container {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  position: relative;
}
.advertise_right_container_table_body_tr {
  text-align: center;
  font-size: 1.4rem;
  display: inline-grid;
}
.imageBox {
  border-radius: 1rem;
  background-color: transparent;
  border: 1px solid var(--light-grey);
  padding: 0.6rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.imageBox img {
  height: 15rem;
  max-width: 30rem;
}
.advertise_img_delete_icon {
  z-index: 9;
  margin-left: 2rem;
  margin-top: 1.4rem;
  transform: translate(-40%, -40%);
  background-color: rgba(255, 0, 0, 0.7);
  border-radius: 3rem;
  height: 3vh;
  width: 3vh;
  padding: 0.5rem;
  fill: white;
  cursor: pointer;
}
