.content {
	display: flex;
	gap: 3rem;
	align-items: flex-start;
}

.mailUsers {
	width: 30%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	padding-right: 1rem;
	height: 70vh;
	overflow: auto;
}

.mail {
	width: 70%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding-right: 1rem;
	height: 70vh;
	overflow: auto;
}

.checkBox {
	position: absolute;
	top: 40%;
	left: 2rem;
	z-index: 101;

	height: 1.3rem;
	width: 1.3rem;
	cursor: pointer;
}
.chatLists_user {
	justify-content: stretch;
	transition: all .6s;
}
.chatLists_user_favourite {
	background-color: #e5e5e5;
}
.chatLists_user_btn_read {
	position: relative;
}
.chatLists_user_btn_read::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, .4);
	border-radius: 2rem;
	z-index: 100;
}

.emailTexts,
.emailAttach {
	display: flex;
	align-items: center;
}
.emailTexts {
	gap: 1rem;
}
.emailAttach {
	gap: .5rem;
	position: absolute;
	top: 50%;
	right: 2rem;
}
.emailAttach div {
	height: 1rem;
	width: 1rem;
	background-color: transparent;
	border: 1px solid var(--red);
	border-radius: 50%;
}
.emailAttach svg {
	height: 1rem;
	width: 1rem;
	fill: var(--grey);
}

.mail_actions {
	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: space-between;
}
.mail_actions__left,
.mail_actions__right {
	display: flex;
	gap: .5rem;
	align-items: center;
}
.mail_actions__left svg {
	height: 1.7rem;
	width: 1.7rem;
	fill: var(--grey);
}
.mail_actions__left div {
	height: 1.3rem;
	width: 1.3rem;
	border: 1px solid var(--grey);
	border-radius: 50%;
}
.mail_actions__right button,
.mail_actions__left button,
.mail_actions__dots {
	background-color: transparent;
	border: none;
	font-family: inherit;
	outline: none;
	cursor: pointer;
}
.mail_actions__dots svg {
	height: 2rem;
	width: 2rem;
	fill: var(--orange);
}
.mail_actions__right button svg {
	height: 2.5rem;
	width: 2.5rem;
	fill: var(--grey);
}
.mail_content {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.mail_content__heading {
	font-size: 1.8rem;
	font-weight: 600;
}
.mail_content__details {
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;
}
.mail_content__recipients,
.mail_content__date {
	font-size: 1.1rem;
	font-weight: 600;
	color: var(--grey);
}
.mail_content__recipients span {
	text-decoration: underline;
}
.mail_content__text {
	font-size: 1.3rem;
	font-weight: 400;
	color: var(--grey);
	white-space: pre-wrap;
}
.mail_attachments {
	display: flex;
	gap: 1.5rem;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 1.5rem;
	padding: 1.5rem 0;
	border-top: 1px solid var(--light-grey);
	border-bottom: 1px solid var(--light-grey);
}
.mail_attachment {
	font-family: inherit;
	border: none;
	cursor: pointer;
	background-color: var(--white);
	border-radius: 1.5rem;
	padding: 1.5rem;
	display: flex;
	align-items: center;
	gap: 1rem;
}
.mail_attachment_info {
	height: 2rem;
	width: 2rem;
	fill: #7cddfc;
}
.mail_attachment p {
	padding-right: 1rem;
	border-right: 1px solid var(--light-grey);
}
.mail_attachment_download {
	height: 1.5rem;
	width: 1.5rem;
	fill: var(--orange);
}
.mail_user_action {
	margin-top: 1.5rem;
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: flex-end;
}
.mail_user_action_btn,
.mail_user_action_btn_dwn {
	font-family: inherit;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 2rem 1.5rem;
	border-radius: 1.5rem;
	transition: all .4s;
}
.mail_user_action_btn {
	background-color: var(--primary-color);
	color: var(--white);
	border: 1px solid var(--primary-color);
}
.mail_user_action_btn_dwn {
	background-color: var(--white);
	color: var(--grey);
	border: 1px solid var(--white);
}
.mail_user_action_btn span {
	border-right: 1px solid var(--grey);
	padding-right: 1rem;
	transition: border-right .4s;
}
.mail_user_action_btn_dwn span {
	border-right: 1px solid var(--light-grey);
	padding-right: 1rem;
	transition: border-right .4s;
}
.mail_user_action_btn_dwn svg,
.mail_user_action_btn svg {
	height: 1.8rem;
	width: 1.8rem;
}
.mail_user_action_btn svg {
	fill: var(--white);
	transition: all .4s;
}
.mail_user_action_btn_dwn svg {
	fill: var(--orange);
}
.mail_user_action_btn:hover {
	background-color: var(--white);
	border: 1px solid var(--white);
	color: var(--primary-color);
}
.mail_user_action_btn:hover svg {
	fill: var(--grey);
}
.mail_user_action_btn:hover span {
	border-right: 1px solid var(--light-grey);
}
.mail_user_reply {
	margin-top: 1.5rem;
	display: flex;
	gap: 1rem;
	flex-direction: column;
}

.filterUsers {
	margin: .5rem 2rem;
	display: flex;
	gap: 2rem;
	align-items: center;
	justify-content: space-between;
}
.filterUser {
	display: flex;
	gap: 1rem;
	align-items: center;
}
.filterUser_checkbox {
	height: 1.5rem;
	width: 1.5rem;
	cursor: pointer;
}
.filterUser_btn {
	font-family: inherit;
	background-color: transparent;
	border: none;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.filterUser_btn svg {
	height: 1.8rem;
	width: 1.8rem;
	fill: var(--grey);
}
.filterUser_text {
	font-size: 1.3rem;
	font-weight: 600;
	color: var(--grey);
}
