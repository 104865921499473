/* VoiceAnalyzer.css */
.voiceAnalyzer {
    color: #5a4343;
    font-size: 16px;
  }

  .assistantContainer{
    background-color: #fff;
    border-radius: 1.5rem;
    padding: 1rem;
    margin: 1rem;
  margin-top: 4rem;
  height: 50rem;
  }

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .headerLeft{
    margin-left: 1.4rem;
  }

  .headerRight{
    background-color: rgba(60, 0, 22, 0.51);
    height: 5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    padding: 1.4rem 1rem;
    border-radius: 6rem;
  }

  .headerLeftText{
display: flex;
gap: 8px;
font-size: 1.4rem;
  }
  
  .voiceIcon{
  width: 2rem;
  height: 3rem;
  }

  .robotIcon{
    width: 2rem;
    height: 3rem; 
    margin-top: -0.4rem;
  }

  .editIcon{
    height: 2rem;
  }

  .summaryButton{
    background-color: rgba(60, 0, 22, 0.51);
    padding: 1rem 2rem;
    border: none;
    color: #fff;
    border-radius: 2rem;
    margin-left: 1rem;
    margin-top: 2rem;
  }