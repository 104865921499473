/* AIButton.css */
.aiButton {
  background-color: #b08989;
  color: #fff;
  padding: 1rem 1.2rem;
  border: none;
  border-radius: 4rem;
  cursor: pointer;
  font-size: 10px;
  position: relative;
  top: 67rem;
  right: 7rem;
  font-weight: bolder;
  }

  .aiIcon{
    height: 2rem;
    width: 2rem;
  }
  