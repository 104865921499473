.content {
  display: grid;
  gap: 2rem;
  grid-template-columns: 25% 75%;
  height: 100%;
}
.right_content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.right_content_middle {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}
.right_content_data {
  height: 72.5vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 0.5rem;
}
.user_card,
.user_card_selected {
  font-family: inherit;
  border: none;
  padding: 2rem 2rem;
  border-radius: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
}
.user_card {
  background-color: var(--white);
}
.user_card:hover {
  background-color: var(--light-grey);
}
.user_card_selected {
  background-color: rgba(60, 0, 22, 0.1);
}
.user_card_content {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.user_card_btns {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.user_card_icon_box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: var(--white);
  box-shadow: 0px 0px 1rem 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.user_card_icon {
  height: 3.5rem;
  width: 3.5rem;
  fill: var(--primary-color);
}
.user_card_date {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 1.2rem;
  color: var(--grey);
}
.user_card_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.user_card_heading {
  text-align: left;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--black);
}
.user_card_user {
  text-align: left;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--black);
}
.user_card_attchment_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--grey);
  margin-right: 1rem;
}
.user_card_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s;
}
.user_card_button_icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: var(--primary-color);
  transition: all 0.4s;
}
.user_card_button:hover {
  background-color: var(--primary-color);
}
.user_card_button:hover .user_card_button_icon {
  fill: var(--white);
}
.form_data {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form_data_title {
  background-color: var(--white);
  padding: 0.5rem 2rem;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 1.5rem;
}
.form_data_content {
  background-color: var(--white);
  border-radius: 1.5rem;
  height: 77vh;
  overflow: auto;
  padding: 2rem 2rem;
}
.form_data_header {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.form_data_header_left {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.form_data_header_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.form_data_header_data h6 {
  font-size: 1.5rem;
  font-weight: 600;
}
.form_data_header_data p,
.form_data_header_date {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--grey);
}
.form_data_content_data {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--black);
}
.form_data_content_data p span {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--black);
  text-transform: capitalize;
}
.form_data_content_data h4 {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--black);
}
.form_data_content_data_attachments {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.form_data_content_data_attachment_data {
  display: flex;
  gap: 1rem;
}
.form_data_content_data_attachment {
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s;
}
.form_data_content_data_attachment:hover {
  background-color: transparent;
  border: 1px solid var(--grey);
}
.form_data_content_data_text {
  color: var(--primary-color);
  font-size: 1.3rem;
  font-weight: 600;
  /* text-transform: uppercase; */
}
.form_data_content_data_attachment_text {
  /* font-size: 1.2rem; */
  font-weight: 600;
  color: var(--black);
}
.form_data_content_data_attachment_icon {
  height: 1.5rem;
  width: 1.5rem;
}
