.inviteModal {
  padding: 0rem;
  background-color: white;
  border-radius: 24px;
  width: 535px;
  height: 250px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.closeIcon{
  width: 2rem;
  margin-top: 0.2rem;
  cursor: pointer;
  height: 2rem;
}

.overlayStyle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(15, 64, 122, 0.24) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioContainer {
  display: flex;
  padding: 1rem 1rem;
  gap: 1rem;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.modalHeading {
  background: #3c0016;
  padding: 1.2rem 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px 24px 0px 0px;
}

.heading {
  color: white;
  font-size: 1.6rem;
}
.optionContainer {
  display: flex;
  gap: 4rem;
}

.radio_label {
  font-size: 1rem;
}

.radio_label {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #3c0016;
}

.radio_custom {
  width: 20px;
  height: 20px;
  border: 2px solid #3c0016;
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
}

.modalContainer{
  padding: 2rem 2rem;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 26px;
  height: 26px;
  border: 2px solid #3c0016;
  border-radius: 50%;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

input[type="radio"]:checked + .radio_custom::after {
  content: "\2713"; /* Checkmark character */
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #3c0016;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  color: white;
}
