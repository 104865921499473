.react-multi-email {
	/* border: none !important; */
	border-radius: 1.5rem !important;
	padding: 1rem 2rem !important;
}
.react-multi-email > input {
	font-family: inherit !important;
}
.react-multi-email-data {
	font-size: 1.2rem !important;
}
.react-multi-email > span[data-placeholder] {
	top: 1rem !important;
	left: 1.5rem !important;
}
.react-multi-email [data-tag] [data-tag-handle] {
	margin-left: .5rem !important;
	display: flex;
	align-items: center;
	justify-content: center;
}
.react-multi-email-data-close-icon {
	height: 1.3rem;
	width: 1.3rem;
	fill: var(--grey);
}
