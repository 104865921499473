.content {
  padding-top: 1rem;
  display: flex;
  gap: 3rem;
  z-index: 0;
  align-items: flex-start;
  overflow: auto;
}

.chatLists {
  min-width: fit-content;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.senderName {
  font-weight: 700;
  font-size: 1.4rem;
}

.removedText {
  font-size: 1.2rem;
  color: var(--primary-color);
  text-align: center;
  position: sticky;
  bottom: 0rem;
  left: 0;
  right: 0;
  background-color: var(--white);
  box-shadow: 0px 0px 1rem 1px rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
  padding: 2rem;
}

.circularLoader {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 1rem;
}

.chatLists_tabs {
  background-color: var(--white);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 1.5rem;
}

.tabContainer {
  display: flex;
  gap: 1rem;
  flex: 1;
  /* width: 90%; */
}

.header_group_chat_icon {
  width: 10%;
  background: none;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  position: relative;
}

.group_chat_input {
  top: -1.5rem;
  opacity: 0;
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  height: 3rem;
  width: 2.3rem;
}

.group_chat_input:hover {
  cursor: pointer;
}

.group_chat_dropdown {
  z-index: 1;
  display: inline-block;
  position: relative;
  margin-left: 5rem;
}

.groupChat_input {
  top: 0.5rem !important;
  opacity: 0 !important;
  display: block !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  position: absolute !important;
  height: 3rem !important;
  width: 3.3rem !important;
}

.chatLists_tab_link {
  background-color: #f1f1f1;
  padding: 1rem 2rem;
  border-radius: 1.5rem;
  width: fit-content;
  text-decoration: none;
  color: var(--grey);
  font-size: 1.3rem;
  font-weight: 700;
  position: relative;
}

.chatLists_tab_link span {
  position: absolute;
  top: -1rem;
  right: 0;
  background-color: var(--orange);
  color: var(--white);
  padding: 0.5rem;
  padding-top: 0.2rem;
  line-height: 1;
  border-radius: 1.5rem;
}

.chatLists_tab_link_active {
  background-color: rgba(60, 0, 22, 1);
  color: var(--white);
}

.chatLists_users {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(60vh - 2.5rem);
  overflow: auto;
  padding-right: 1rem;
  transition: transform 0.3s ease-in-out;
}

.chatLists_users1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: calc(60vh - 2.5rem);
  overflow: auto;
  padding-right: 0.5rem;
}

.chatLists_user_btn {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  font-family: inherit;
  text-align: left;
}

.chatLists_user {
  background-color: var(--white);
  border-radius: 2rem;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.chatLists_user_data {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.chatLists_user_current {
  transition: transform 0.3s ease-in-out;
  background-color: rgba(60, 0, 22, 0.1);
}

.chatLists_user_imageBox {
  background-color: var(--white);
  box-shadow: 0px 0px 1rem 1px rgba(0, 0, 0, 0.5);
  border-radius: 50%;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
}

.chatLists_user_image {
  border-radius: 50%;
  height: 4rem;
}

.chatLists_user_active,
.chatLists_user_deactive {
  position: absolute;
  top: 0;
  right: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
}

.chatLists_user_active {
  background-color: var(--green);
}

.chatLists_user_deactive {
  background-color: var(--grey);
}

.chatLists_user_name {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--black);
  line-height: 1;
}

.chatLists_user_email {
  font-size: 1.2rem;
}

.chatLists_user_msg {
  font-size: 1.1rem;
  color: var(--grey);
  font-weight: 600;
}

.chatLists_user_msg_new {
  font-size: 1.1rem;
  color: var(--black);
  font-weight: 600;
  /* display: flex;
	align-items: center;
	justify-content: space-between; */
}

.chatLists_user_msg_new span {
  position: absolute;
  top: 3.5rem;
  right: 2rem;
  font-family: 'Poppins', sans-serif;
  background-color: var(--green);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.chatLists_num_msg {
  background-color: var(--primary-color);
  border-radius: 1rem;
  color: var(--white);
  font-size: 1rem;
  padding: 0.6rem;
  line-height: 0.5;
}

.chatLists_user_date {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-weight: 600;
  color: var(--grey);
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
}

.chat_placeholder {
  height: 100%;
  min-width: 600px;
  flex: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chat_placeholder_heading {
  font-size: 2rem;
  text-align: center;
  color: var(--grey);
}

.chat_placeholder_subHeading {
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: center;
  color: var(--grey);
}

.chat {
  height: 100%;
  min-width: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.chatBox_user {
  background-color: var(--white);
  border-radius: 2rem;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.chatBox_user_data {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.chatLists_user_key {
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.chatBox {
  background-color: var(--white);
  border-radius: 2rem;
  padding: 1.5rem 2rem;
  position: relative;
  height: 60vh;
  overflow: auto;
  /* padding-right: 3rem; */
  /* box-shadow: 0px 0px 1rem 1px rgba(0, 0, 0, .2); */
}

.chatBox_loadMore {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.chatBox_loadMoreBtn {
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  color: var(--orange);
  cursor: pointer;
}

.chatBox_msg {
  text-align: center;
  margin: 1rem 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--grey);
}

.chatBox_date {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--grey);
  font-family: 'Poppins', sans-serif;
}

.chatBox_chats {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
  min-height: calc(60vh - 19rem);
}

.noChats {
  margin-top: calc(60vh - 21rem);
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--grey);
  text-align: center;
}

.chatBox_inc_chat {
  max-width: 80%;
  align-self: flex-start;
  position: relative;
}

.chatBox_chat_react {
  position: absolute;
  top: -36px;
  right: 12rem;
  transform: translate(100%, -50%);

  display: flex;
  gap: 1.5rem;
  background-color: var(--white);
  box-shadow: 0 5px 10px #d3d3d3;
  padding: 1rem 2rem;
  border-radius: 1rem;
  /* top: 0; */
}

.chatBox_chat_react button {
  font-family: inherit;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.4s;
}

.chatBox_chat_react button:hover {
  transform: scale(1.1);
}

.chatBox_chat_msgs {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.chatBox_chat_inc_msg_smiley {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.chatBox_chat_inc_msg_smiley svg {
  height: 2rem;
  width: 2rem;
  fill: var(--grey);
}

.chatBox_chat_inc_msg_reply {
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}

.chatBox_chat_inc_msg_reply svg {
  height: 2rem;
  width: 2rem;
  fill: var(--grey);
}

.chatBox_chat_msgs:hover .chatBox_chat_inc_msg_reply {
  opacity: 1;
  visibility: visible;
}

.chatBox_out_chat {
  max-width: 80%;
  align-self: flex-end;
  position: relative;
}

.chatBox_chat_inc_msg,
.chatBox_chat_out_msg {
  border-radius: 1.5rem;
  padding: 1rem 1.4rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--grey);
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
}

.group_user_name {
  font-weight: 700;
  font-size: 1.3rem;
}

.chatBox_chat_inc_msg {
  min-height: fit-content;
  background-color: #f1f1f1;
  border-bottom-left-radius: 0;
}

.chatBox_chat_out_msg {
  background-color: rgba(60, 0, 22, 0.1);
  border-bottom-right-radius: 0;
}

.chatBox_inc_chat .chatBox_chat_time,
.chatBox_out_chat .chatBox_chat_time {
  margin-top: 0.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--grey);
  font-family: 'Poppins', sans-serif;
}

.chatBox_inc_chat .chat_reply_reaction,
.chatBox_out_chat .chat_reply_reaction {
  position: absolute;
  bottom: -0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1rem;
}

.chatBox_inc_chat .chat_reply_reaction {
  right: 1rem;
}

.chatBox_out_chat .chat_reply_reaction {
  left: 1rem;
}

.chat_reply_reaction p {
  background-color: var(--light-grey);
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatBox_inc_chat .chatBox_chat_time {
  text-align: left;
}

.chatBox_out_chat .chatBox_chat_time {
  text-align: right;
}

.chat_send_smiley_container {
  position: relative;
}

.chatBox_chat_emoji {
  position: absolute;
  bottom: 3rem;
  right: 0;

  background-color: var(--white);
  box-shadow: 0 5px 10px #efefef;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat_send,
.chat_send_errr {
  position: sticky;
  bottom: 1rem;
  left: 0;
  right: 0;

  background-color: var(--white);
  box-shadow: 0px 0px 1rem 1px rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.chat_send {
  padding: 1.5rem;
  border: 1px solid var(--white);
}

.chat_send_errr {
  border: 1px solid var(--red);
}

.chat_send_textBox {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.chat_reply {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey);
  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
}

.chat_reply_msg {
  font-size: 1.2rem;
  font-weight: 600;
}

.chat_reply_msg_name {
  font-size: 1rem;
  font-style: italic;
}

.chat_reply_close {
  background-color: transparent;
  border: none;
}

.chat_reply_close svg {
  height: 1.3rem;
  width: 1.3rem;
  fill: var(--grey);
  transition: all 0.4s;
  cursor: pointer;
}

.chat_reply_close:hover svg {
  fill: var(--red);
}

.chat_send_text {
  padding-top: 1.5rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--black);
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  resize: none;
  white-space: pre-wrap;
}

.chat_send_smiley {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.chat_send_smiley svg {
  height: 2rem;
  width: 2rem;
  fill: var(--grey);
}

.chat_send_message_loading {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding: 1.3rem 0;
  width: 6rem;
  cursor: pointer;
}

.chat_send_message {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  transition: all 0.4s;
}

.chat_send_message svg {
  height: 2rem;
  width: 2rem;
  fill: var(--white);
  transition: all 0.4s;
}

.chat_send_message:hover {
  background-color: transparent;
}

.chat_send_message:hover svg {
  height: 2rem;
  width: 2rem;
  fill: var(--primary-color);
}

.chatLists_user_req_btns {
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.chatLists_user_acc_btn,
.chatLists_user_can_btn {
  background-color: transparent;
  border: none;
  font-size: 1.1rem;
  text-decoration: underline;
  cursor: pointer;
}

.chatLists_user_acc_btn {
  color: var(--green);
}

.chatLists_user_can_btn {
  color: var(--red);
}

.chatLists_req {
  background-color: var(--white);
  border-radius: 1.5rem;
  padding: 1.5rem;
  font-size: 1.3rem;
  font-weight: 700;
  color: var(--grey);
}

.chatBox_key {
  margin-top: 1rem;
  background-color: #f1f1f1;
  border-radius: 1.5rem;
  padding: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--grey);
}

.chatBox_key p {
  color: var(--primary-color);
  text-decoration: underline;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
}

.chatBox_key button {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.4s;
}

.chatBox_key button:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.chatBox_key_add {
  position: sticky;
  left: 2rem;
  bottom: 2rem;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.chatBox_key_add button {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  padding: 1rem 2rem;
  color: var(--white);
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.4s;
}

.chatBox_key_add button:hover {
  background-color: transparent;
  color: var(--primary-color);
}

.chatBox_user_right_container {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.video_call_button,
.video_call_button_disabled {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video_call_button svg,
.video_call_button_disabled svg {
  height: 2rem;
  width: 2rem;
}
.video_call_button_disabled svg {
  fill: var(--grey);
}
.video_call_button svg {
  fill: var(--primary-color);
}
