.content{
    display: flex;
    gap:3rem ;
    align-items: center;
}
.mailUsers{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    height: 75vh;
    overflow: auto;   
}
.filter{
    margin: .5rem 2rem;
	display: flex;
	gap: 25rem;
	align-items: center;
	justify-content: space-between;
}
.filterUser{
    display: flex;
    justify-content: space-between;
}

.filterUser_text{
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--grey);
}

.mail{
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-right: 1rem;
  height: 75vh;
  overflow: auto
}

.Buttons{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
}