@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');


.headerContainer{
  display: flex;
  top: 19rem;
  left: 3rem;
  justify-content: flex-start;
  position: relative;
}

.logo{
  width: 9rem;
  margin-top: 3rem;
}

.header {
  align-items: center;
  justify-content: space-between;
  background-color: #4d4d4d75;
  padding: 10px 16px;
  width: 21rem;
  border-radius: 5rem;
  height: 6rem;
  margin-left: 3rem;
}

.backButton {
  background-color: #00000040;
  cursor: pointer;
  padding: 1rem;
  border-radius: 3rem;
  border: none;
  margin-left: -1rem;
  margin-top: 1rem;
}

.backButtonIcon {
  width: 2.3rem;
  height: 2rem;
}

.backButton:hover {
  text-decoration: underline;
}

.headerContent {
  flex-grow: 1;
  flex-wrap: wrap;
  margin-left: 1rem;
  text-align: left;
}

.meetingTitle {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

.hostName {
  margin: 0;
  font-size: 1.2rem;
  color: #c3c3c3;
}

.shareButton {
  background-color: #fff;
  color: white;
  border: none;
  border-radius: 10rem;
  padding: 10px 7px;
  cursor: pointer;
  margin-right: -1rem;
  margin-top: 1rem;
}

.shareButton:hover {
  background-color: #0056b3;
}

.shareIcon{
  width: 3rem;
  height: 2rem;
}

.headerTitle{
  display: flex;
}

.totalParticipants{
  color: #fff;
  font-size: 1.2rem;
  margin-left: 1rem;
  font-family: 'Montserrat', sans-serif;
  word-spacing: 1px;
  font-weight: bold;
}

.participantThumbnailsContainer {
  display: flex;
  align-items: center;
  margin-left: 23rem;
  gap: 0.6rem;
  margin-bottom: 3rem;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 14rem;
  overflow: hidden;
  position: relative;
  background-color: #fdfdfdb5;
  border-radius: 4px;
}

.thumbnailImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.participantName {
  font-size: 1rem;
  text-align: center;
  display: flex;
  width: 14rem;
}

.extraParticipants {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 14rem;
  background-color: #00000047;
  color: #ffffffb0;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 4px;
}

